import {
  ContentText,
  ContentWrapper,
  HeadlineSmall,
} from "../../content/components";
import WeddingRSVPForm from "../../guest-management/forms/RSVPForm/Wedding/WeddingRSVPForm";
import RSVPSearchModal from "../../guest-management/forms/RSVPSearchModal/RSVPSearchModal";
import { WrapperLarge } from "../../layout/website";
import Page from "../../pages/website/Page";
import { selectSite } from "../../website/selectors";
import LegacyRSVPForm from "./LegacyRSVPForm";
import { css } from "@emotion/react";
import { Button as FancyButton } from "@minted/fancyclothes";
import { TextInput, theme } from "@minted/minted-components";
import React, { useState } from "react";
import { connect } from "react-redux";

const oiStyle = css`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;
`;

const style = (textAlignment) => css`
  max-width: 500px;
  ${theme.media.greaterThan(theme.breakpoints.small)(`
    margin:
      20px
      ${textAlignment === "left" || textAlignment === "center" ? "auto" : "20px"}
      20px
      ${textAlignment === "right" || textAlignment === "center" ? "auto" : "20px"};
  `)}
`;

const rsvpPageStyles = {
  cookieError: css`
    ${theme.typeStyles.bodyMediumStyles};
    text-align: center;
    color: ${theme.colors.negative800};
    margin-bottom: ${theme.spacing.x2};
  `,
  nameInput: css`
    margin-bottom: ${theme.spacing.x4};
    text-align: left;
  `,
};

const RSVP = ({
  configuration,
  id,
  isPreview,
  removeSpacing = false,
  site,
  title,
}) => {
  const [rsvpData, setRsvpData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showRSVPModal, setShowRSVPModal] = useState(false);
  const isInBrowser = typeof window !== "undefined";
  const hasCookies = isInBrowser && window.navigator.cookieEnabled;

  return (
    // This has to exist way up here to avoid dealing with inherited styling
    site.isUsingGuestManagement && rsvpData ? (
      <WeddingRSVPForm
        contact={rsvpData.contact}
        events={rsvpData.events}
        guests={rsvpData.guests}
        site={site}
      />
    ) : (
      <Page id={id} removeSpacing title={rsvpData ? "" : title}>
        <WrapperLarge>
          <ContentWrapper
            extraCss={[
              site.isOnlineInvitation
                ? oiStyle
                : style(configuration.textAlignment),
            ]}
            imageAlignment="top"
            noVerticalMargin
            textAlignment={configuration.textAlignment}
          >
            {isInBrowser && (
              <>
                <ContentText textAlignment={configuration.textAlignment}>
                  <HeadlineSmall>Enter Your Name to RSVP</HeadlineSmall>
                </ContentText>
                {hasCookies ? (
                  site.isUsingGuestManagement ? (
                    <>
                      {showRSVPModal && (
                        <RSVPSearchModal
                          closeModal={() => setShowRSVPModal(false)}
                          initialSearchTerm={searchTerm}
                          setRsvpData={setRsvpData}
                          site={site}
                        />
                      )}
                      <div css={rsvpPageStyles.nameInput}>
                        <TextInput
                          disabled={isPreview || site.isDemoSite}
                          onChange={(event) => {
                            setSearchTerm(event.target.value);
                          }}
                          onKeyDown={(event) =>
                            event.key === "Enter" && setShowRSVPModal(true)
                          }
                          size="small"
                          touched
                          value={searchTerm}
                        />
                      </div>
                      <FancyButton
                        block={true}
                        disabled={isPreview || site.isDemoSite}
                        onClick={() => setShowRSVPModal(true)}
                        text="Find RSVP"
                      />
                    </>
                  ) : (
                    <LegacyRSVPForm isReadOnly={isPreview || site.isDemoSite} />
                  )
                ) : (
                  <div css={rsvpPageStyles.cookieError}>
                    Cookies are required to submit your RSVP. Please enable
                    cookies in your browser.
                  </div>
                )}
              </>
            )}
          </ContentWrapper>
        </WrapperLarge>
      </Page>
    )
  );
};

const mapStateToProps = (state) => {
  const site = selectSite(state.resources.sites);

  return {
    site,
  };
};

export default connect(mapStateToProps)(RSVP);

import API from "../../../../core/api";
import { ATTENDING, AWAITING_REPLY } from "../../../../guests/constants";
import { getGuestTokenCookie } from "../../../../website/actions";
import { isValidEmail } from "../../../dashboard/utils";
import { scrollToElement } from "../../utils";
import EventDetails from "../EventDetails";
import { QUESTION_WITH_ERROR_CLASSNAME } from "../constants";
import OnlineInvitationEventGuest from "./OnlineInvitationEventGuest";
import OnlineInvitationEventsGuestsSummary from "./OnlineInvitationEventsGuestsSummary";
import { css } from "@emotion/react";
import {
  Button,
  theme,
  styleUtils,
  useMediaQueryState,
} from "@minted/minted-components";
import isEmpty from "lodash/isEmpty";
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";

const rsvpStyles = {
  buttonsWrapper: css`
    margin-bottom: ${theme.spacing.x5};
    display: flex;
    gap: ${theme.spacing.x4};
  `,
  buttonWrapper: css`
    display: flex;
    margin-top: ${theme.spacing.x4};
    margin-bottom: ${theme.spacing.x4};
  `,
  emailMargins: css`
    margin-left: ${theme.spacing.x5};
    margin-right: ${theme.spacing.x5};
    margin-bottom: ${theme.spacing.x5};
  `,
  formWrapper: css`
    background-color: ${theme.colors.gray200};
    display: grid;
    gap: ${theme.spacing.x5};
    padding: ${theme.spacing.x5};
    ${theme.media.greaterThan("small")`
      padding: ${theme.spacing.x4};
    `};
  `,
  partySizeLabel: css`
    min-width: ${styleUtils.rem(100)};
  `,
  partySizeWrapper: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  wrapper: css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.textPrimary};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x4};
    margin-top: ${theme.spacing.x5};
    margin-left: auto;
    margin-right: auto;
    max-width: ${styleUtils.rem(660)};
    padding-left: ${theme.spacing.x4};
    padding-right: ${theme.spacing.x4};
    text-align: initial;
    width: 100%;
  `,
};

const DESKTOP_NAVBAR_OFFSET = 54;
const MOBILE_NAVBAR_OFFSET = 44;

const OnlineInvitationRSVPForm = ({ contact, event, guest, siteId }) => {
  const [guestResponse, setGuestResponse] = useState({
    answers: {},
    responseType: AWAITING_REPLY,
  });
  // Tracking this because it may be temporary if the invite is open
  const [guestId, setGuestId] = useState(guest.id);
  const [contactData, setContactData] = useState(contact);
  const [showSummary, setShowSummary] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [validateFields, setValidateFields] = useState(false);

  const wrapper = useRef(null);
  const mediumMediaQueryState = useMediaQueryState({
    mediaQuerySize: "medium",
  });
  const isMobile = mediumMediaQueryState === "BELOW";

  // used to automatic scroll on errors or next/submit click
  const navbarOffset = isMobile ? MOBILE_NAVBAR_OFFSET : DESKTOP_NAVBAR_OFFSET;

  const isFormComplete = useMemo(() => {
    if (!contactData.id) {
      if (
        // Name is required
        !contactData.name ||
        // Email is required if attending
        (guestResponse.responseType === ATTENDING && !contactData.email) ||
        // If email is valued, it must be valid
        (contactData.email && !isValidEmail(contactData.email))
      ) {
        return false;
      }
    }
    const requiredQuestions = event.questions.filter(
      (question) => question.required,
    );

    if (guestResponse.responseType === AWAITING_REPLY) {
      return false;
    } else if (guestResponse.responseType === ATTENDING) {
      // All required questions must be answered
      for (const question of requiredQuestions) {
        if (!guestResponse.answers[question.id]) {
          return false;
        }
      }
    }

    return true;
  }, [
    contactData.id,
    contactData.name,
    contactData.email,
    event.questions,
    guestResponse.responseType,
    guestResponse.answers,
  ]);

  const updateGuestResponse = useCallback((newResponse) => {
    setGuestResponse(newResponse);

    setValidateFields(false);
  }, []);

  const updateContactData = useCallback((fieldName, newValue) => {
    // Passing in data like {name: "New contact name"}
    setContactData((prevContactData) => ({
      ...prevContactData,
      [fieldName]: newValue,
    }));
    setValidateFields(false);
  }, []);

  const maxPartySize = guest.maxParty;
  const [partySize, setPartySize] = useState(contactData.id ? maxPartySize : 1);

  const incrementPartySize = useCallback(() => {
    setPartySize((prevCount) => prevCount + 1);
  }, []);

  const decrementPartySize = useCallback(() => {
    setPartySize((prevCount) => (prevCount > 1 ? prevCount - 1 : 1));
  }, []);

  const submitRsvp = useCallback(() => {
    if (!isFormComplete) {
      setValidateFields(true);
      setTimeout(() => {
        const wrappersWithErrors = document.querySelectorAll(
          `.${QUESTION_WITH_ERROR_CLASSNAME}`,
        );

        if (!isEmpty(wrappersWithErrors)) {
          scrollToElement(wrappersWithErrors[0], {
            offset: navbarOffset,
          });
        }
      }, 1);

      return null;
    }

    const guestToken = getGuestTokenCookie();
    const headers = {
      Authorization: `Guest ${guestToken}`,
    };

    // Flatten answers object to list
    const answers = [];

    for (const [questionId, answer] of Object.entries(guestResponse.answers)) {
      answers.push({
        answer,
        questionId,
      });
    }
    const requestData = {
      addressbookContactId: contactData.id,
      answers,
      eventId: event.id,
      // Guest ID is set to 1 for form state management, un-set that
      // on RSVP submit so the backend knows to create a new guest
      guestId: contactData.id ? guestId : null,
      // Contact name has to be valued for open invitations, email does not
      newContactEmail: contactData.email,
      newContactName: contactData.name,
      partySize,
      responseType: guestResponse.responseType,
    };

    setIsSubmitting(true);
    API.post(
      "guest-management/rsvp-online-invitation",
      {
        headers,
      },
      requestData,
    )
      .then((responseData) => {
        if (!requestData.addressbookContactId) {
          // New contact and guest were created, make sure to use those for updates later
          setContactData((prevContactData) => {
            return {
              ...prevContactData,
              id: responseData.contactId,
            };
          });
          setGuestId(responseData.guestId);
        }
        setShowSummary(true);

        scrollToElement(wrapper.current, {
          offset: navbarOffset,
        });
      })
      .finally(() => setIsSubmitting(false));
  }, [
    isFormComplete,
    contactData.id,
    contactData.email,
    contactData.name,
    event.id,
    guestId,
    partySize,
    guestResponse.responseType,
    guestResponse.answers,
    navbarOffset,
  ]);

  const onChangeResponse = () => {
    setCurrentEventIndex(0);
    setShowSummary(false);
  };

  useEffect(() => {
    scrollToElement(wrapper.current, {
      offset: navbarOffset,
    });
  }, [currentEventIndex, navbarOffset]);

  return (
    <div css={rsvpStyles.wrapper} data-cy="rsvpForm" ref={wrapper}>
      {showSummary ? (
        <div>
          <OnlineInvitationEventsGuestsSummary
            contactData={contactData}
            event={event}
            guest={guest}
            guestResponse={guestResponse}
            isMobile={isMobile}
            partySize={partySize}
            siteId={siteId}
          />
          <div css={rsvpStyles.buttonWrapper}>
            <Button
              expand
              onClick={onChangeResponse}
              size="large"
              text="Change Response"
              type="primary"
            />
          </div>
        </div>
      ) : (
        <>
          <EventDetails
            event={event}
            index={currentEventIndex}
            isMobile={isMobile}
            totalEvents={1}
          />

          <div css={rsvpStyles.formWrapper}>
            <OnlineInvitationEventGuest
              contactData={contactData}
              decrementPartySize={decrementPartySize}
              guest={guest}
              guestResponse={guestResponse}
              incrementPartySize={incrementPartySize}
              maxPartySize={maxPartySize}
              partySize={partySize}
              questions={event.questions}
              updateContactData={updateContactData}
              updateGuestResponse={updateGuestResponse}
              validateFields={validateFields}
            />
          </div>
          <Button
            disabled={isSubmitting}
            expand
            onClick={submitRsvp}
            size="large"
            text="Submit"
            type="primary"
          />
        </>
      )}
    </div>
  );
};

export default OnlineInvitationRSVPForm;

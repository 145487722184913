import { contentConfiguration, contentStyles } from "../../content";
import {
  ContentText,
  ContentWrapper,
  HeadlineSmall,
  HeadlineTiny,
  Image,
  RichTextContent,
} from "../../content/components";
import API from "../../core/api";
import { BLANK_SLATE_DOCUMENT } from "../../core/constants";
import getSiteTypeData from "../../core/getSiteTypeData";
import { countryNameByCode } from "../../guest-management/forms/constants";
import { getEventDateTimeComponent } from "../utils";
import EventDetail from "./EventDetail";
import ListNumber from "./ListNumber";
import { css } from "@emotion/react";
import { ActionLink, MediumHeadline } from "@minted/fancyclothes";
import { styleUtils, theme } from "@minted/minted-components";
import isEqual from "lodash/isEqual";
import { bool, array, string } from "prop-types";
import React, { useEffect, useState } from "react";

const wordWrapStyles = css`
  overflow: hidden;
`;

const eventHeadingStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const eventNumMarginStyles = css`
  margin-right: ${theme.spacing.x3};
`;

const eventStyles = {
  attending: css`
    margin-top: ${theme.spacing.x4};
    margin-bottom: ${theme.spacing.x4};
  `,
  attendingWrapper: css`
    margin-top: ${theme.spacing.x12};
  `,
  eventInfo: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: ${styleUtils.rem(780)};
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${styleUtils.rem(40)};
    ${theme.media.greaterThan(theme.breakpoints.medium)(`
      flex-direction: row;
    `)};
  `,
  guestName: css`
    list-style-type: none;
  `,
  infoBlock: css`
    width: 100%;
    ${theme.media.lessThan(theme.breakpoints.medium)(`
      margin-bottom: ${theme.spacing.x4};
    `)};
  `,
  infoValue: css`
    margin-top: ${theme.spacing.x3};
    margin-bottom: ${theme.spacing.x4};
  `,
  schedule: css`
    margin-left: auto;
    margin-right: auto;
    max-width: ${styleUtils.rem(620)};
  `,
  wrapper: css``,
};

const EventListNumber = ({ extraCss, number, showListNumber }) =>
  showListNumber ? (
    <div css={extraCss}>
      <ListNumber>{number}</ListNumber>
    </div>
  ) : null;

const Event = ({
  address1,
  address2,
  city,
  configuration,
  country,
  date,
  description,
  endDate,
  endTime,
  formattedAddress,
  hasPhysicalAddress,
  hostedBy,
  image,
  index,
  showAttending,
  showListNumber,
  showNotAttending,
  siteType,
  state,
  time,
  title,
  venue,
  zipCode,
}) => {
  const { isOnlineInvitationSite, isWeddingSite } = getSiteTypeData({
    siteType,
  });
  const [guests, setGuests] = useState([]);
  const [hasLoadedGuestList, setHasLoadedGuestList] = useState(false);

  useEffect(() => {
    if (isOnlineInvitationSite) {
      API.get("guest-management/visible-guest-list").then((data) => {
        setHasLoadedGuestList(true);

        return setGuests(data);
      });
    }
  }, [isOnlineInvitationSite]);

  const attendingGuests = guests.filter((guest) => guest.attending);
  const notAttendingGuests = guests.filter((guest) => !guest.attending);

  const attendingCount = attendingGuests
    .map((guest) => guest.count)
    .reduce((total, guestCount) => total + guestCount, 0);
  const notAttendingCount = notAttendingGuests
    .map((guest) => guest.count)
    .reduce((total, guestCount) => total + guestCount, 0);

  if (isWeddingSite) {
    return (
      <ContentWrapper
        extraCss={[contentStyles.relative, contentStyles.mx0Md]}
        imageAlignment={configuration.imageAlignment}
        textAlignment={configuration.textAlignment}
      >
        <Image
          image={image}
          imageAlignment={configuration.imageAlignment}
          imageSize={configuration.imageSize}
        />

        <ContentText
          extraCss={[contentStyles.flex, contentStyles.flexRow]}
          textAlignment={configuration.textAlignment}
        >
          <div
            css={[
              image ? contentStyles.flex5 : contentStyles.flex9,
              wordWrapStyles,
            ]}
          >
            <div css={eventHeadingStyles} data-cy="subheading">
              <EventListNumber
                extraCss={eventNumMarginStyles}
                number={index + 1}
                showListNumber={showListNumber}
              />
              <HeadlineSmall>{title}</HeadlineSmall>
            </div>
            <div data-cy="body">
              <EventDetail
                address={formattedAddress}
                date={date}
                endDate={endDate}
                endTime={endTime}
                time={time}
                venue={venue}
              />

              {description && <RichTextContent value={description} />}

              {hasPhysicalAddress && (
                <ActionLink
                  target="_blank"
                  text="Get Directions"
                  url={"https://www.google.com/maps/dir/?api=1&destination=".concat(
                    encodeURIComponent(formattedAddress),
                  )}
                />
              )}
            </div>
          </div>
        </ContentText>
      </ContentWrapper>
    );
  } else if (isOnlineInvitationSite) {
    return (
      <ContentWrapper
        extraCss={[
          contentStyles.relative,
          contentStyles.mx0Md,
          eventStyles.wrapper,
        ]}
        imageAlignment={configuration.imageAlignment}
        noVerticalMargin
        textAlignment={configuration.textAlignment}
      >
        <div css={eventStyles.eventInfo}>
          {hostedBy && (
            <div css={eventStyles.infoBlock}>
              <HeadlineTiny>Hosted By</HeadlineTiny>
              <div css={eventStyles.infoValue}>{hostedBy}</div>
            </div>
          )}
          <div css={eventStyles.infoBlock}>
            <HeadlineTiny>Date</HeadlineTiny>
            <div css={eventStyles.infoValue}>
              {getEventDateTimeComponent(date, time, endDate, endTime)}
            </div>
          </div>
          {(venue || hasPhysicalAddress) && (
            <div css={eventStyles.infoBlock}>
              <HeadlineTiny>Address</HeadlineTiny>
              <div css={eventStyles.infoValue}>
                {venue && venue !== address1 && (
                  <>
                    {venue}
                    <br />
                  </>
                )}
                {hasPhysicalAddress && (
                  <>
                    {address1 && (
                      <>
                        {address1}
                        {address2 ? `, ${address2}` : ""}
                        <br />
                      </>
                    )}
                    {city || state || zipCode
                      ? `${city || ""}, ${state || ""} ${zipCode || ""}`
                      : ""}
                    {country && country !== "US" && (
                      <>
                        <br />
                        {countryNameByCode[country]}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        {description && !isEqual(description, BLANK_SLATE_DOCUMENT) && (
          <div>
            <MediumHeadline>Event Schedule</MediumHeadline>
            <div css={eventStyles.schedule}>
              <RichTextContent value={description} />
            </div>
          </div>
        )}
        {hasLoadedGuestList && (showAttending || showNotAttending) && (
          <div css={eventStyles.attendingWrapper}>
            <MediumHeadline>Guest List</MediumHeadline>
            {showAttending && (
              <>
                <div css={eventStyles.attending}>
                  {`${attendingCount} GUEST${attendingCount === 1 ? "" : "S"} ATTENDING`}
                </div>
                {attendingGuests.map((guest, index) => {
                  return (
                    <li css={eventStyles.guestName} key={`attending-${index}}`}>
                      {guest.name} {guest.count > 1 && `(${guest.count})`}
                    </li>
                  );
                })}
              </>
            )}
            {showNotAttending && (
              <>
                <div css={eventStyles.attending}>
                  {`${notAttendingCount} GUEST${notAttendingCount === 1 ? "" : "S"} NOT ATTENDING`}
                </div>
                {notAttendingGuests.map((guest, index) => {
                  return (
                    <li
                      css={eventStyles.guestName}
                      key={`notAttending-${index}}`}
                    >
                      {guest.name} {guest.count > 1 && `(${guest.count})`}
                    </li>
                  );
                })}
              </>
            )}
          </div>
        )}
      </ContentWrapper>
    );
  }
};

Event.propTypes = {
  address1: string,
  address2: string,
  city: string,
  configuration: contentConfiguration.configurationPropTypes,
  date: string.isRequired,
  description: array,
  endDate: string,
  endTime: string,
  formattedAddress: string,
  hasPhysicalAddress: bool,
  hostedBy: string,
  showListNumber: bool,
  siteType: string.isRequired,
  state: string,
  time: string,
  title: string,
  venue: string,
  zipCode: string,
};

export default Event;

import customize from "../../customize/reducers";
import dashboard from "../../dashboard/reducers";
import events from "../../events/reducers";
import activeModal from "../../modals/reducers";
import createResourceReducer from "../../resources/reducers";
import inFlight from "../../resources/reducers/inFlight";
import theme from "../../themes/reducers";
import site from "./site";
import { rootReducer as addressbookInternal } from "@minted/addressbook";
import { combineReducers } from "redux";
import { router5Reducer } from "redux-router5";

const resourceNames = [
  "pages",
  "sections",
  "events",
  "guests",
  "rsvps",
  "sites",
  "registries",
  "attendants",
  "accommodations",
  "image-uploads",
  "photos",
  "tasks",
  "invites",
  "comments",
  "videos",
];

export default combineReducers({
  activeModal,
  addressbookInternal,
  customize,
  dashboard,
  events,
  inFlight,
  resources: createResourceReducer(resourceNames),
  router: router5Reducer,
  site,
  theme,
});

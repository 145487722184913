import { eventPropTypes } from "../../../../global/propTypes";
import EventDetails from "../EventDetails";
import OnlineInvitationEventGuest from "./OnlineInvitationEventGuest";
import OnlineInvitationSignup from "./OnlineInvitationSignup";
import { css } from "@emotion/react";
import { theme } from "@minted/minted-components";
import { shape, string, number, bool } from "prop-types";
import React from "react";

const eventsGuestsSummaryStyles = {
  dividerWrapper: css`
    margin-top: ${theme.spacing.x5};
    margin-bottom: 0;
  `,
  eventGuestWrapper: css`
    background-color: ${theme.colors.gray200};
    display: grid;
    gap: ${theme.spacing.x5};
    margin-bottom: ${theme.spacing.x4};
    padding: ${theme.spacing.x4};
    ${theme.media.lessThan("medium")`
      padding: ${theme.spacing.x5};
    `};
  `,
  headerWrapper: css`
    text-align: center;
    padding-top: ${theme.spacing.x12};
    padding-bottom: ${theme.spacing.x5};
    margin-left: ${theme.spacing.x5};
    margin-right: ${theme.spacing.x5};
    ${theme.media.lessThan("medium")`
      margin-left: ${theme.spacing.x4};
      margin-right: ${theme.spacing.x4};
    `};
  `,
  headingText: css`
    ${theme.typeStyles.titleExtraLargeStyles};
  `,
  subHeadingText: css`
    ${theme.typeStyles.bodySmallStyles};

    margin-bottom: ${theme.spacing.x3};
  `,
};

const OnlineInvitationEventsGuestsSummary = ({
  contactData,
  event,
  guest,
  guestResponse,
  isMobile,
  partySize,
  siteId,
}) => {
  return (
    <div data-cy="eventsGuestsSummary">
      <OnlineInvitationSignup siteId={siteId} />
      <div css={eventsGuestsSummaryStyles.headerWrapper}>
        <div css={eventsGuestsSummaryStyles.subHeadingText}>
          Thank you for responding!
        </div>
      </div>

      <div key={`eventWrapper${event.id}`}>
        <EventDetails
          event={event}
          index={0}
          isMobile={isMobile}
          key={`summaryEvent${event.id}`}
          readOnly
          totalEvents={1}
        />

        <div css={eventsGuestsSummaryStyles.eventGuestWrapper}>
          <OnlineInvitationEventGuest
            contactData={contactData}
            guest={guest}
            guestResponse={guestResponse}
            key={`summaryGuest${guest.id}`}
            partySize={partySize}
            questions={event.questions}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

OnlineInvitationEventsGuestsSummary.propTypes = {
  contactData: shape({
    email: string,
    id: string,
    name: string,
  }).isRequired,
  event: eventPropTypes.isRequired,
  guest: shape({
    firstName: string,
    id: number.isRequired,
    lastName: string,
  }).isRequired,
  guestResponse: shape({}).isRequired,
  isMobile: bool.isRequired,
};

export default OnlineInvitationEventsGuestsSummary;

import { CASH_REGISTRY_TYPES } from "../constants";
import { css } from "@emotion/react";
import { styleUtils, theme } from "@minted/minted-components";
import config from "config";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";

const embeddedCashRegistryWrapperStyles = css`
  position: relative;
  box-shadow: 0 1px 4px 0 #00000040;

  & > div {
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  height: ${styleUtils.rem(663)};
  max-width: ${styleUtils.rem(728)};
  width: 100%;

  ${theme.media.lessThan(theme.breakpoints.medium)(`
    height: ${styleUtils.rem(618)};
    max-width: ${styleUtils.rem(335)};
    width: 100%;
  `)}
`;

const cashRegistryWrapperStyles = css`
  position: relative;
  border: ${styleUtils.rem(1.5)} solid #64cdd6;
  box-shadow: 1px 1px 6px 0px #bae9ed;

  & > div {
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  height: ${styleUtils.rem(515)};
  // We can't use the standard breakpoints to determine mobile since it's in the sidebar and determining width of the sidebar.
  // This is the given spec from birdie (iframe width > 376px: 515px, iframe width <= 376px: 564px)
  // With 27 pixels added for the margin from iframe to sidebar width
  ${theme.media.lessThan(styleUtils.rem(403))(`
     height: ${styleUtils.rem(564)}
  `)}
`;

let BirdieModule;

if (typeof BirdieRegistry !== "undefined") {
  // eslint-disable-next-line no-undef
  BirdieModule = BirdieRegistry.driver("react", {
    React,
    ReactDOM,
  });
}

const CashRegistry = ({
  hostView,
  isWedding = true,
  onCancel = () => {},
  onClose = () => {},
  onRegistryCreate,
  onRegistryDelete,
  onRegistryUpdate,
  prefilledData,
  registryId,
  registrySlug,
}) => {
  const onCreate = (...args) => {
    const originalData = {
      ...args,
    };

    const mergedData = {
      ...originalData[0],
      ...originalData[1],
    };

    // This is so that when we pass description to the backend, it's not on the payload. We want the default slate
    // document if it's not valued, otherwise, we want to convert the description into the slate document.
    if (!mergedData.description) {
      delete mergedData.description;
    }

    onRegistryCreate({
      ...mergedData,
    });
  };

  const onUpdate = (...args) => {
    const originalData = {
      ...args,
    };

    const simplifiedData = originalData[0];

    const transformedData = {
      cashRegistryId: simplifiedData.identifier,
      description: [
        {
          align: "center",
          children: [
            {
              text: simplifiedData.description,
            },
          ],
          type: "paragraph",
        },
      ],
      slug: simplifiedData.slug,
      title: simplifiedData.title,
    };

    onRegistryUpdate({
      ...transformedData,
    });
  };

  if (BirdieModule === undefined) {
    return <div></div>;
  }

  return (
    <div
      css={
        registrySlug
          ? embeddedCashRegistryWrapperStyles
          : cashRegistryWrapperStyles
      }
      data-cy="birdieGiftSection"
    >
      <BirdieModule
        clientId={config.get("birdieClientId")}
        hostView={hostView ? hostView : null}
        occasion={
          isWedding ? CASH_REGISTRY_TYPES.WEDDING : CASH_REGISTRY_TYPES.OTHER
        }
        onCancel={onCancel ? onCancel : null}
        onClose={onClose ? onClose : null}
        onRegistryCreate={onRegistryCreate ? onCreate : null}
        onRegistryDelete={onRegistryDelete ? onRegistryDelete : null}
        onRegistryUpdate={onRegistryUpdate ? onUpdate : null}
        prefilledData={
          Object.keys(prefilledData?.registry || {}).length > 0
            ? prefilledData
            : null
        }
        registryId={registryId}
        registrySlug={registrySlug ? registrySlug : null}
        themeKey="mintedv2"
      />
    </div>
  );
};

CashRegistry.propTypes = {
  hostView: PropTypes.string,
  isWedding: PropTypes.bool,
  onCancel: PropTypes.func, // Used for the edit/manage iframe
  onClose: PropTypes.func, // Used for the create iframe
  onRegistryCreate: PropTypes.func,
  onRegistryDelete: PropTypes.func,
  onRegistryUpdate: PropTypes.func,
  registryId: PropTypes.string,
  registrySlug: PropTypes.string, // Used for embedded iframe on live site
};

export default CashRegistry;

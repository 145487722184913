import PageTitle from "./PageTitle";
import { css } from "@emotion/react";
import { LargeDivider } from "@minted/fancyclothes";
import { DesignComponent } from "@minted/fancyclothes";
import { styleUtils } from "@minted/minted-components";
import PropTypes from "prop-types";
import React from "react";

const centeredTextStyles = css`
  text-align: center;
`;

const marginBottomStyles = (siteType) => css`
  margin-bottom: ${styleUtils.rem(siteType === "online_invitation" ? 40 : 80)};
`;

const paddingTopStyles = (siteType) => css`
  padding-top: ${styleUtils.rem(siteType === "online_invitation" ? 40 : 80)};
`;

class Page extends DesignComponent {
  render() {
    const { children, id, removeSpacing = false, siteType, title } = this.props;
    const themeHasDividers = !!this.getRule("graphic", "LargeDivider");

    return (
      <>
        <div css={!removeSpacing && paddingTopStyles(siteType)} id={id}>
          <div css={centeredTextStyles}>
            {!!title && (
              <PageTitle hideDivider={id === "registry"}>{title}</PageTitle>
            )}
          </div>
          <div
            css={
              (!removeSpacing || themeHasDividers) &&
              marginBottomStyles(siteType)
            }
          >
            {children}
          </div>

          <LargeDivider />
        </div>
      </>
    );
  }
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default Page;

import {
  ATTENDING,
  ATTENDING_STATUS_LABEL,
  AWAITING_REPLY,
  NOT_ATTENDING,
} from "../../../../guests/constants";
import { isValidEmail } from "../../../dashboard/utils";
import { QUESTION_WITH_ERROR_CLASSNAME } from "../constants";
import { css } from "@emotion/react";
import {
  theme,
  Divider,
  ButtonPicker,
  ButtonPickerButton,
  NumericStepper,
  Textarea,
  FadeReveal,
  styleUtils,
  Icon,
  TextInput,
} from "@minted/minted-components";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import { string, number, arrayOf, shape, func, bool, object } from "prop-types";
import React, { useCallback } from "react";

const eventGuestStyles = {
  attendingStatusText: css`
    ${theme.typeStyles.bodyMediumStyles}
  `,
  attendingStatusWrapper: css`
    display: flex;
    justify-content: end;
    align-items: center;
    width: fit-content;
    float: right;
  `,
  buttonPickerWrapper: (hasError) => css`
    ${hasError &&
    `
      button {
        border: 1px solid ${theme.colors.alert};
        &:hover {
          border: 1px solid ${theme.colors.alert};
        }
      }
    `}
  `,
  card: css`
    background-color: ${theme.colors.gray100};
    border: ${styleUtils.rem(1)} solid ${theme.colors.gray300};
    border-radius: ${theme.spacing.base};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x6};
    padding: ${theme.spacing.x4};
    padding-bottom: ${theme.spacing.x4};
  `,
  column: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2};
  `,
  dividerWrapper: css`
    margin-top: ${theme.spacing.base};
    margin-bottom: ${theme.spacing.base};
  `,
  emailDisclaimer: css`
    ${theme.typeStyles.bodySmallStyles};
    color: ${theme.colors.textSecondary};
  `,
  errorStyle: css`
    ${theme.typeStyles.bodySmallStyles};
    color: #cb2647;
    margin-top: 0.25rem;
  `,
  fadeReveal: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x6};
  `,
  fullWidth: css`
    width: 100%;
  `,
  headerWrapper: css`
    display: inline-block;
  `,
  nameText: css`
    ${theme.typeStyles.titleMediumStyles};
    float: left;
  `,
  numericStepperText: css`
    ${theme.typeStyles.bodySmallStyles};
    ${theme.typeStyles.bodyBoldStyles};
  `,
  openInviteInputWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2};
  `,
  questionText: css`
    ${theme.typeStyles.titleAlternateMediumStyles};
  `,
  questionWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x4};
  `,
  readOnlyResponseText: css`
    ${theme.typeStyles.bodySmallStyles}
  `,
  row: css`
    display: flex;
    gap: ${theme.spacing.x2};
  `,
  // This exists because the error spacing will actually render outside of the textarea allocated space.
  // Something to do with the textarea inheriting display: block; somewhere, and seems to be an issue
  // that happens only for hitchedup on Chrome. Could be a global styling somewhere.
  textAreaOverrides: css`
    & > * {
      display: flex;
      flex-direction: column;
    }
  `,
};

const ButtonPickerWrapper = ({ children, hasError }) => (
  <div css={eventGuestStyles.buttonPickerWrapper(hasError)}>
    {children}
    {hasError && (
      <div css={eventGuestStyles.errorStyle}>Question is required</div>
    )}
  </div>
);

export const Question = ({
  answer,
  onChange,
  question,
  readOnly,
  shouldValidateErrors,
}) => {
  const hasError = question.required && isEmpty(answer) && shouldValidateErrors;
  const errorMessage = "Question is required";

  return (
    <div
      className={classNames({
        [QUESTION_WITH_ERROR_CLASSNAME]: hasError,
      })}
      css={eventGuestStyles.questionWrapper}
    >
      <div css={eventGuestStyles.questionText}>
        {question.text}
        {!question.required && " (Optional)"}
      </div>

      {readOnly ? (
        <div css={eventGuestStyles.readOnlyResponseText}>
          {answer || "(skipped)"}
        </div>
      ) : (
        <>
          {question.questionType === "freetext" && (
            <div css={eventGuestStyles.textAreaOverrides}>
              <Textarea
                error={hasError && errorMessage}
                hasErrorSpacing
                minRows={2}
                name={question.text}
                onChange={(event) => onChange(event, question.id)}
                placeholder="Your message"
                touched
                value={answer}
              />
            </div>
          )}

          {question.questionType === "multiple_choice" && (
            <ButtonPickerWrapper hasError={hasError}>
              <ButtonPicker
                name={question.text}
                onChange={(event) => onChange(event, question.id)}
                size="small"
                value={answer}
              >
                {question.choices.map((choice) => (
                  <ButtonPickerButton key={choice} value={choice}>
                    {choice}
                  </ButtonPickerButton>
                ))}
              </ButtonPicker>
            </ButtonPickerWrapper>
          )}
        </>
      )}
    </div>
  );
};

const propTypes = {
  contactName: string,
  decrementPartySize: func,
  guest: shape({
    firstName: string,
    id: number.isRequired,
    lastName: string,
  }).isRequired,
  guestResponse: object,
  incrementPartySize: func,
  maxPartySize: number,
  partySize: number,
  questions: arrayOf(
    shape({
      choices: arrayOf(string),
      questionType: string.isRequired,
      required: bool.isRequired,
      text: string.isRequired,
    }),
  ),
  readOnly: bool,
  updateGuestResponses: func,
  validateErrors: bool,
};

const defaultProps = {
  canEditName: noop,
  updateGuestName: noop,
};

const OnlineInvitationEventGuest = ({
  contactData,
  decrementPartySize,
  guest,
  guestResponse,
  incrementPartySize,
  maxPartySize,
  partySize,
  questions,
  readOnly,
  updateContactData,
  updateGuestResponse,
  validateFields,
}) => {
  const showQuestions =
    (!readOnly && !isEmpty(questions)) ||
    (readOnly &&
      guestResponse.responseType === ATTENDING &&
      !isEmpty(questions));

  const onAttendingChange = useCallback(
    (event) => {
      event.persist();

      updateGuestResponse({
        ...guestResponse,
        responseType: event.target.value,
      });
    },
    [updateGuestResponse, guestResponse],
  );

  const onAnswerChange = useCallback(
    (event, questionId) => {
      event.persist();

      updateGuestResponse({
        ...guestResponse,
        answers: {
          ...guestResponse.answers,
          [questionId]: event.target.value,
        },
      });
    },
    [guestResponse, updateGuestResponse],
  );

  const iconFillColor = css`
    svg > path {
      fill: ${guestResponse.responseType === ATTENDING
        ? theme.colors.accent600
        : theme.colors.negative600};
    }
  `;

  const hasStatusError =
    guestResponse.responseType === AWAITING_REPLY && validateFields;

  let nameErrorMessage;
  let emailErrorMessage;

  if (validateFields) {
    if (!contactData.name) {
      nameErrorMessage = "Name is required";
    }

    if (guestResponse.responseType === ATTENDING && !contactData.email) {
      emailErrorMessage = "Email is required";
    } else if (contactData.email && !isValidEmail(contactData.email)) {
      emailErrorMessage = "Email is not valid";
    }
  }

  return (
    <div css={eventGuestStyles.card}>
      <>
        <div css={eventGuestStyles.headerWrapper}>
          <div css={eventGuestStyles.nameText}>
            {contactData.id ? contactData.name : "RSVP"}
          </div>

          {readOnly && (
            <div
              css={css`
                ${eventGuestStyles.attendingStatusWrapper};
                ${iconFillColor}
              `}
            >
              <Icon
                size="small"
                type={
                  guestResponse.responseType === ATTENDING ? "check" : "close"
                }
              />

              <div css={eventGuestStyles.attendingStatusText}>
                {` ${partySize} `}
                {ATTENDING_STATUS_LABEL[guestResponse.responseType]}
              </div>
            </div>
          )}
        </div>

        {showQuestions && (
          <div css={eventGuestStyles.dividerWrapper}>
            <Divider spacing="none" type="light" />
          </div>
        )}
      </>

      {!readOnly && (
        <>
          <div
            className={classNames({
              [QUESTION_WITH_ERROR_CLASSNAME]: hasStatusError,
            })}
            css={eventGuestStyles.questionWrapper}
          >
            <div css={eventGuestStyles.questionText}>
              Will you be attending this event?
            </div>

            <ButtonPickerWrapper hasError={hasStatusError}>
              <ButtonPicker
                layout="horizontalLayout"
                name="attending"
                onChange={onAttendingChange}
                size="small"
                value={guestResponse.responseType}
              >
                <ButtonPickerButton value={ATTENDING}>
                  Attending
                </ButtonPickerButton>

                <ButtonPickerButton value={NOT_ATTENDING}>
                  Not Attending
                </ButtonPickerButton>
              </ButtonPicker>
            </ButtonPickerWrapper>
          </div>
          {
            // If open invite response and a response is selected, display
            guestResponse.responseType !== AWAITING_REPLY &&
              !contactData.id && (
                <div css={eventGuestStyles.openInviteInputWrapper}>
                  <div css={eventGuestStyles.numericStepperText}>
                    {guestResponse.responseType === ATTENDING
                      ? "Your Details"
                      : "Confirm your name"}
                  </div>
                  <div
                    className={classNames({
                      [QUESTION_WITH_ERROR_CLASSNAME]: !!nameErrorMessage,
                    })}
                  >
                    <TextInput
                      error={nameErrorMessage}
                      label="Your Name"
                      name="newContactName"
                      onChange={(event) =>
                        updateContactData("name", event.target.value)
                      }
                      touched
                      value={contactData.name}
                    />
                  </div>
                  <div
                    className={classNames({
                      [QUESTION_WITH_ERROR_CLASSNAME]: !!emailErrorMessage,
                    })}
                  >
                    <TextInput
                      error={emailErrorMessage}
                      label={`Your Email${guestResponse.responseType === NOT_ATTENDING ? " (Optional)" : ""}`}
                      name="newContactEmail"
                      onChange={(event) =>
                        updateContactData("email", event.target.value)
                      }
                      touched
                      value={contactData.email}
                    />
                  </div>
                  {guestResponse.responseType === ATTENDING && (
                    <div css={eventGuestStyles.emailDisclaimer}>
                      We'll only send you important updates about this event,
                      nothing else
                    </div>
                  )}
                </div>
              )
          }

          {guestResponse.responseType === ATTENDING && (
            <div css={eventGuestStyles.questionWrapper}>
              <div css={eventGuestStyles.numericStepperText}>
                Number Attending
              </div>
              <NumericStepper
                maxValue={maxPartySize}
                minValue={1}
                onMinusClick={decrementPartySize}
                onPlusClick={incrementPartySize}
                size="small"
                value={partySize}
              />
            </div>
          )}
        </>
      )}

      {showQuestions && (
        <FadeReveal
          css={eventGuestStyles.fadeReveal}
          in={guestResponse.responseType === ATTENDING && !isEmpty(questions)}
          unmountOnExit
        >
          {questions.map((question) => (
            <Question
              answer={guestResponse.answers[question.id]}
              key={`${guest.id}${question.id}`}
              onChange={onAnswerChange}
              question={question}
              readOnly={readOnly}
              shouldValidateErrors={
                guestResponse.responseType === ATTENDING && validateFields
              }
            />
          ))}
        </FadeReveal>
      )}
    </div>
  );
};

OnlineInvitationEventGuest.propTypes = propTypes;
OnlineInvitationEventGuest.defaultProps = defaultProps;

export default OnlineInvitationEventGuest;

import { NOT_ATTENDING } from "../../guests/constants";
import isEmpty from "lodash/isEmpty";

const getEventQuestionData = (events) => {
  const eventsWithRequiredQuestions = events.filter(({ questions }) =>
    questions.some(({ required }) => required),
  );

  return Object.fromEntries(
    eventsWithRequiredQuestions.map(({ id, questions }) => [
      id,
      questions
        .filter((question) => question.required)
        .map((question) => question.id),
    ]),
  );
};

export const getGuestsWithMissingResponses = (guests, events) => {
  const eventQuestionData = getEventQuestionData(events);

  return guests.map((guest) => {
    const { invitedToEventIds, nameUnknown, responses } = guest;

    // Guest has no name, not attending any events
    if (nameUnknown) {
      return {
        ...guest,
        hasMissingResponses: false,
      };
    }

    // Events that have required questions
    const eventIdsToValidate =
      invitedToEventIds?.filter((eventId) => eventQuestionData[eventId]) || [];

    // Guest not invited to any events with required questions
    if (eventIdsToValidate === 0) {
      return {
        ...guest,
        hasMissingResponses: false,
      };
    }

    // Check if there are any required questions this guest has not answered
    const hasMissingResponses = eventIdsToValidate.some((eventId) => {
      const eventQuestions = eventQuestionData[eventId];
      const isNotAttending =
        responses.find((res) => res.eventId === eventId)?.responseType ===
        NOT_ATTENDING;

      // Return false because answers are only required from attending party members
      if (isNotAttending) {
        return false;
      }

      const eventQuestionsAnswers =
        responses.find((res) => res.eventId === eventId)?.answers ?? [];

      // Return true if there are no question responses for that event
      if (isEmpty(eventQuestionsAnswers)) {
        return true;
      }

      // Return true if there are any unanswered questions
      return eventQuestions.some((requiredQuestionId) => {
        const questionAnswer = eventQuestionsAnswers.find(
          ({ questionId }) => questionId === requiredQuestionId,
        );

        return isEmpty(questionAnswer?.answer);
      });
    });

    return {
      ...guest,
      hasMissingResponses,
    };
  });
};

export const getGuestsWithMissingResponsesIndexes = (guests, events) => {
  const guestsWithMissingResponses = getGuestsWithMissingResponses(
    guests,
    events,
  );
  const guestWithMissingResponsesIds = [];

  for (const [index, guest] of Object.entries(guestsWithMissingResponses)) {
    if (guest.hasMissingResponses) {
      guestWithMissingResponsesIds.push(parseInt(index));
    }
  }

  return guestWithMissingResponsesIds;
};

export const scrollToElement = (element, options) => {
  if (!element) {
    return;
  }

  const {
    isInsideFrame = false,
    offset = 0,
    scrollIntoView = false,
  } = options ?? {};

  const top = element.getBoundingClientRect().top;
  const scrollY = window.scrollY;
  const isVisible = top - offset > 0;

  // there's no way to detect the visibility state inside an iframe so that's why we can only use this method
  if (isInsideFrame) {
    element.scrollIntoView({
      behavior: "smooth",
    });

    return;
  }

  if (!isVisible) {
    if (scrollIntoView) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: top + scrollY - offset,
      });
    }
  }
};
